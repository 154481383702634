import { createSlice } from "@reduxjs/toolkit";
const currentYear = new Date().getFullYear();

const initialState = {
  appState: "",
  bankAccStatus: "",
  driverRiderStatus: "drivers",
  addEditStatus: "add",
  enableEdit: false,
  openDrawer: true,
  userData: {},
  cardsData: [],
  currentPage: 0,
  riders: [],
  helpCenterList: [],
  creditCardsList: [],
  staticMassagesList: [],
  savedAddressesList: [],
  ticketsList: [],
  driversReports: [],
  selectedDriver: {},
  selectedUser: {},
  selectedCustomer: {},
  details: {},
  selectedRide: {},
  outChat: false,
  driversList: [],
  blockedDriversList: [],
  driverRequirementsList: [],
  openchatFromDriver: false,
  ridesTypeList: [],
  zonesList: [],
  driversReq: [],
  ridesList: [],
  ridesScheduledList: [],
  admins: [],
  selectedChat: { type: null, id: null },
  zones: [],
  rides: "activeRides",
  ridesListhistory: [],
  contactUsList: [],
  addNewRide: {
    first_name: "",
    last_name: "",
    phone_number: "",
    address: {
      lat: "",
      lng: "",
      address: "",
      city: "",
      state: "",
      zip_code: "",
    },
    addressDrop: {
      lat: "",
      lng: "",
      address: "",
      city: "",
      state: "",
      zip_code: "",
    },
    carType: null,
    isCash: false,
    isScheduled: false,
    selectedDateTime: null,
    cardData: null,
  },
  formData: {
    address: {},
    options: {},
    currency: "",
    name: "",
    email: "",
    radius: 0,
    tax: 0,
    type: "radius",
    additional_fees: [],
    show_customer_details: false,
    show_dropOff_point: false,
    accept_request_before_finishing_current_ride: false,
    accept_request_before_finishing_current_ride_time_per_min: 0,
    driver_cancellation_fees: {
      watting_time: 0,
      fees: 0,
    },
    customer_cancellation_fees: {
      watting_time: 0,
      fees: 0,
    },
    tipping: false,
    extra_cost_from_driver: false,
    total_charge_display: false,
    post_pay: false,
    cash: false,
    digital_payment: false,
    car_type: [
      // {
      //   base_cost: 0,
      //   min_cost: 0,
      //   waiting_time: 0,
      //   waiting_time_cost: 0,
      //   service: {
      //     tax: 0,
      //     type: "amount",
      //   },
      //   company_percentage: 0,
      //   is_schedule_ride: false,
      //   schedule_ride_time: 0,
      //   all_time: false,
      //   schedule: {},
      //   plans: [],
      //   type: [""],
      //   male: false,
      //   fmale: false,
      // },
    ],
  },
  formDataDoc: {
    address: {},
    options: {},
    currency: "",
    name: "",
    email: "",
    radius: 0,
    tax: 0,
    type: "radius",
    additional_fees: [],
    show_customer_details: false,
    show_dropOff_point: false,
    accept_request_before_finishing_current_ride: false,
    accept_request_before_finishing_current_ride_time_per_min: 0,
    driver_cancellation_fees: {
      watting_time: 0,
      fees: 0,
    },
    customer_cancellation_fees: {
      watting_time: 0,
      fees: 0,
    },
    tipping: false,
    extra_cost_from_driver: false,
    total_charge_display: false,
    post_pay: false,
    cash: false,
    digital_payment: false,
    car_type: [
      // {
      //   base_cost: 0,
      //   min_cost: 0,
      //   waiting_time: 0,
      //   waiting_time_cost: 0,
      //   service: {
      //     tax: 0,
      //     type: "amount",
      //   },
      //   company_percentage: 0,
      //   is_schedule_ride: false,
      //   schedule_ride_time: 0,
      //   all_time: false,
      //   schedule: {},
      //   plans: [],
      //   type: [""],
      //   male: false,
      //   fmale: false,
      // },
    ],
  },
  carType: {},
  divisions: [
    { key: "start", value: 0, label: "0" },
    { key: "end", value: 9999, label: "9999" },
  ],
  plan: [
    {
      start_distance: 0,
      end_distance: 9999,
      speeds: [],
      value: 9999,
    },
  ],
  selectedPayout: [],
  payrollListStripe: [],
  payrollListCash: [],
  setPayrollTabIndex: 0,
  contact_usCount: 0,
  addNewBalance: false,
  lastRideChat: null,
  lastSupportChat: null,
  selectedYear: currentYear + '',
  corporatesList: [],
  selectedCorporate: {},
  selectedCorporateRides: [],
  vouchersList: [],
  selectedVoucher: {},
  corporateCustomersList: [],
  corporateEmployeesList: [],
  selectedCorporateMember: {},
  newRideChat: {},
  walletHistoryList: [],
  discountsRequestsList: [],
  discountProgramsList: [],
  selectedDiscountRequest: {},
  activeLightbox: false,
  urlLoading: false,
  selecedEndRideInfo: {},
  selectedRideToEnd: {},
  platformWalletHistoryList: [],
  selectedScheduledRide: {},
};

export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setAppState: (state, action) => {
      state.appState = action.payload;
    },
    setBankAccStatus: (state, action) => {
      state.bankAccStatus = action.payload;
    },
    setWalletBalance: (state, action) => {
      const { amount, transaction } = action.payload;
      if (transaction === 'add') {
        state.selectedUser.wallet_balance += amount;
      } else if (transaction === 'deduct') {
        state.selectedUser.wallet_balance -= amount;
      }
    },
    setOpenDrawer: (state, action) => {
      state.openDrawer = action.payload;
    },
    setEnableEdit: (state, action) => {
      state.enableEdit = action.payload;
    },
    loggedIn: (state, action) => {
      state.login = action.payload;
      state.isLoggedIn = true;
    },
    logOut: (state, action) => {
      state.login = null;
      state.isLoggedIn = false;

    },
    userData: (state, action) => {
      state.userData = action.payload;
    },
    setCardsData: (state, action) => {
      state.cardsData = action.payload;
    },
    setDriversList: (state, action) => {
      state.driversList = action.payload;
    },
    setBlockedDriversList: (state, action) => {
      state.blockedDriversList = action.payload;
    },
    setDriverRequirementsList: (state, action) => {
      state.driverRequirementsList = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    setRidesTypeList: (state, action) => {
      state.ridesTypeList = action.payload;
    },
    setZoneData: (state, action) => {
      state.zonesList = action.payload;
    },
    setOpenChatFromDriver: (state, action) => {
      state.openchatFromDriver = action.payload;
    },
    setRidesList: (state, action) => {
      state.ridesList = action.payload;
    },
    setContactUsList: (state, action) => {
      state.contactUsList = action.payload;
    },
    selectRide: (state, action) => {
      state.selectedRide = action.payload;
    },
    setScheduledRidesList: (state, action) => {
      state.ridesScheduledList = action.payload;
    },
    setRides: (state, action) => {
      state.rides = action.payload;
    },
    setRidesListHistory: (state, action) => {
      state.ridesListhistory = action.payload;
    },
    setDriversReqList: (state, action) => {
      state.driversReq = action.payload;
    },
    setRadius: (state, action) => {
      state.radius = action.payload;
    },
    setDivisions: (state, action) => {
      state.divisions = action.payload;
    },
    setRidersList: (state, action) => {
      state.riders = action.payload;
    },
    setAdminsList: (state, action) => {
      state.admins = action.payload;
    },
    setPayoutList: (state, action) => {
      state.payout = action.payload;
    },
    setSelectedPayout: (state, action) => {
      state.selectedPayout = action.payload;
    },
    setZonesList: (state, action) => {
      state.zones = action.payload;
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    saveLocationInfo: (state, action) => {
      state.saveLocationInfo = action.payload;
    },
    saveCoordinates: (state, action) => {
      state.saveCoordinates = action.payload;
    },
    selectDriver: (state, action) => {
      state.selectedDriver = action.payload;
    },
    saveZones: (state, action) => {
      state.saveZones = action.payload;
    },
    saveFees: (state, action) => {
      state.saveFees = action.payload;
    },
    chat: (state, action) => {
      state.chat = action.payload;
    },
    selectChat: (state, action) => {
      state.selectedChat = action.payload;
    },
    updateForm(state, action) {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetForm: (state) => {
      state.formData = initialState.formData;
    },
    updateFormData(state, action) {
      state.formDataDoc = { ...state.formDataDoc, ...action.payload };
    },
    setCarType(state, action) {
      state.carType = { ...state.carType, ...action.payload };
    },
    currentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setAddNewRide: (state, action) => {
      state.addNewRide = { ...state.addNewRide, ...action.payload };
    },
    resetAddNewRide: (state) => {
      state.addNewRide = {};
    },
    updateOutChat: (state, action) => {
      state.outChat = action.payload;
    },
    setPayrollList: (state, action) => {
      state.payrollList = action.payload;
    },
    setPayrollTabIndex: (state, action) => {
      state.payrollTabIndex = action.payload;
    },
    setYear: (state, action) => {
      state.selectedYear = action.payload + "";
    },
    setAddNewBalance: (state, action) => {
      state.addNewBalance = !state.addNewBalance;
    },
    setLastRideChat: (state, action) => {
      state.lastRideChat = action.payload;
    },
    setLastSupportChat: (state, action) => {
      state.lastSupportChat = action.payload;
    },
    setCorporatesList: (state, action) => {
      state.corporatesList = action.payload;
    },
    setSelectedCorporate: (state, action) => {
      state.selectedCorporate = action.payload;
    },
    setContact_usCount: (state, action) => {
      state.contact_usCount = action.payload;
    },
    postDataRedux: (state, action) => {
      const { stateName, data, push } = action.payload;
      if (stateName && data && !push)
        state[stateName] = data;
      else if (stateName && data && push) {
        state[stateName].unshift(data);
      }
    },
    deleteDataRedux: (state, action) => {
      const { stateName, key, value } = action.payload;
      const currentState = state[stateName];

      if (Array.isArray(currentState)) {
        state[stateName] = currentState.filter(item => item[key] !== value);
      } else {
        state[stateName] = {};
      }
    },
    putDataRedux: (state, action) => {
      const { stateName, key, value, data } = action.payload;
      const currentState = state[stateName];

      if (Array.isArray(currentState)) {
        const index = currentState.findIndex(item => item[key] === value);
        if (index !== -1) {
          state[stateName][index] = { ...currentState[index], ...data };
        } else {
          console.log('item not found for update')
        }
      } else {
        state[stateName] = data;
      }
    },
  },
});

export const {
  postDataRedux,
  putDataRedux,
  deleteDataRedux,
  setYear,
  setAddNewBalance,
  setPayrollList,
  setLastSupportChat,
  setAppState,
  setLastRideChat,
  setPayrollTabIndex,
  setOpenDrawer,
  setEnableEdit,
  loggedIn,
  logOut,
  userData,
  setCardsData,
  cardsData,
  setSelectedPayout,
  setDriversList,
  setBlockedDriversList,
  setDriverRequirementsList,
  setRidersList,
  resetAddNewRide,
  setRidesList,
  setScheduledRidesList,
  setDriversReqList,
  setRadius,
  saveLocationInfo,
  saveCoordinates,
  saveZones,
  saveFees,
  chat,
  setDivisions,
  setPlan,
  updateForm,
  resetForm,
  currentPage,
  setAdminsList,
  setPayoutList,
  setContactUsList,
  setZonesList,
  setRidesListHistory,
  setRides,
  selectDriver,
  selectRide,
  setAddNewRide,
  selectChat,
  updateOutChat,
  updateFormData,
  setCarType,
  setRidesTypeList,
  setOpenChatFromDriver,
  setZoneData,
  setBankAccStatus,
  setWalletBalance,
  setSelectedUser,
  setContact_usCount,
} = appStateSlice.actions;

export default appStateSlice.reducer;
