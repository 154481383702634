import { createSlice } from '@reduxjs/toolkit';
const licAndConfigSlice = createSlice({
    name: 'licencesAndConfiguration',
    initialState: {
        licenses: {
            always_women: false,
            apple_pay: false,
            bank_account: true,
            create_ride_by_dispatch: true,
            dispatch_view: true,
            driver_accept_while_active: false,
            driver_additional_doc: true,
            driver_can_add_extra_cost: false,
            edit_address_for_zone: true,
            female_only_drivers: true,
            google_pay: false,
            max_admin_number: 3,
            max_car_type_number: 3,
            max_zone_number: 3,
            payment_method: true,
            payment_type: "stripe",
            polygon_address: true,
            scheduled_ride: true,
            send_and_request_money: true,
            skip_drop_off_address: false,
            step_point: false,
            top_up_wallet: true,
            update_drop_off_address: false,
        },
        config: {
            additional_fees: true,
            bloked_wallet_amount: 30,
            cancel_ride_count_daly: 5,
            cash: true,
            customer_profile_pic_required: true,
            dark_mode: true,
            gallery_for_customer_profile_pic: true,
            language: {
                ar: true,
                en: true,
                es: true,
                he: true,
                zh: true,
            },
            payout_scheduled_time: {
                day_of_week: [],
                days: [],
                type: "week" | "daily" | "month"
            },
            rate_app: true,
            rate_ride: true,
            reject_ride_count_daly: 4,
            share_app: true,
            tips: true
        },
    },
    reducers: {
        setlicAndConfigSlice: (state, action) => {
            state.licenses = action.payload.licenses;
            state.config = action.payload.config;
            // console.log("action =>", action);
        }
    },
});

export const { setlicAndConfigSlice } = licAndConfigSlice.actions;
export default licAndConfigSlice.reducer;