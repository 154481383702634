import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: process.env.REACT_APP_ENV === 'women_first' ? '#431799' : "#006496",
    color: "#eeeeee",
    hoverBg: "white",
    activeBg: "#0075b0",
    meduimColor: process.env.REACT_APP_ENV === 'women_first' ? '#7b4cd6' : '#C0EAFF',
    ligthBg: process.env.REACT_APP_ENV === 'women_first' ? '#F9F6FF' : "#f5fcff"
  },
  topbar: {
    bg: "#fff",
    color: "#000",
  },
  mainBg: colors.grey["100"],
  mainColor: "#000000",
  lightColor: "#EEEEEE7A",
  grayText: "#727272",
  button: "#0075b0",
  excelJS: process.env.REACT_APP_ENV === 'women_first' ? 'ff431799' : "ff006496",
};

export default colorConfigs;