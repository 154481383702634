import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCountry: process.env.REACT_APP_ENV === 'women_first' ? "CA" : "US",
};

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    setCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
  },
});

export const { setCountry } = countrySlice.actions;
export default countrySlice.reducer;
